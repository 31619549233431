import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'

export default function Home() {
  const router = useRouter()
  const { data, isValidating } = useSWR('/api/mad-mint/projects/firstproject', {
    revalidateOnFocus: false,
  })
  useEffect(() => {
    if (!isValidating && data) {
      router.push(`/${data.slug}`)
    }
  }, [data, isValidating, router])

  return <>loading~~~</>
}
